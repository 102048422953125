import { useCallback, useEffect, useState } from 'react'
import { toLocaleString } from 'utils'
import { useInoContract } from './useContract'

const useGetBoxInfo = () => {
  const contractMethod = useInoContract()
  const [data, setData] = useState<any>(null)
  const [phaseData, setPhaseData] = useState(0)

  const getData = useCallback(async () => {
    if (contractMethod) {
      const arr = []
      try {
        const currentPhase = await contractMethod.TOTAL_SALE_BOX()

        for (let i = 1; i <= +currentPhase; i++) {
          arr.push(contractMethod.saleBoxs(i))
        }

        const boxInfo = await Promise.all(arr)

        if (boxInfo) {
          setData(
            boxInfo?.map((item, idx) => ({
              currentBox: +item?.currentBox,
              endSale: +item?.endSale,
              priceBox: toLocaleString(+item?.priceBox),
              startSale: +item?.startSale,
              totalBox: +item?.totalBox,
              typeBox: +item?.typeBox,
              phase: idx + 1,
            })),
          )
        }

        setPhaseData(+currentPhase)
      } catch (error) {
        console.error('useGetBoxInfo', error)
      }
    }
  }, [contractMethod])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData, phaseData]
}

export default useGetBoxInfo
