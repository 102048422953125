import { useWeb3React } from '@web3-react/core'
import ModalLoadingV2 from 'components/ModalLoading/ModalLoadingV2'
import { convertKeysToCamelCase } from 'helpers/CommonHelper'
import setListNFTs from 'hooks/setListNFTs'
import useGetBoxInfo from 'hooks/useBoxQuantity'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setMyNft } from 'state/nft/actions'
import styled from 'styled-components'
import { Skeleton } from 'widgets'
import { ArrowForwardIcon } from '../../../packages/uikit/src/components/Svg'
import SaleBox from './components/SaleBox'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: 42px 0;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 80px 0;
  }

  .container {
    max-width: 1032px;
  }
`

const BuyBoxLink = styled.div`
  background-color: #5297ff70;
  padding: 4px;
  border-radius: 8px;
  cursor: pointer;

  svg {
    fill: #fff;
  }

  :hover {
    opacity: 0.8;
  }
`

const Home = () => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const [isLoadedBanner, setIsLoadedBanner] = useState(false)

  const [boxList, fetchBox] = useGetBoxInfo()

  useEffect(() => {
    if (account) {
      setListNFTs(
        { limit: 100, owner: account },
        (res) => {
          dispatch(setMyNft(convertKeysToCamelCase(res?.data)))
        },
        (e) => {
          dispatch(setMyNft(null))
        },
      )
    }
  }, [dispatch, account])

  return (
    <>
      <div className="flex flex-col gap-[20px] mt-[142px] w-full max-w-[1152px] mx-auto px-4">
        <div
          className="w-full  aspect-[112/27] mx-auto relative flex items-center justify-center bg-cover bg-center"
          style={{ backgroundImage: isLoadedBanner ? '' : "url('/images/background/banner.png')" }}
        >
          <Image
            src="/images/background/banner.png"
            className={`${isLoadedBanner ? 'block' : 'hidden'} w-full h-full object-cover`}
            layout="fill"
            onLoadingComplete={() => {
              setIsLoadedBanner(true)
            }}
            loading="lazy"
          />
        </div>

        <div className="grid lg:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-4">
          {[1, 2, 3, 4, 5, 6].map((x) => (
            <Image
              key={x}
              src={`/images/home/banner-${x}.png`}
              width={172}
              height={172}
              loading="lazy"
              className="md:!max-h-[172px] !max-h-[150px]  md:!min-h-[172px] !min-h-[150px]  md:!max-w-[172px] !max-w-[150px]  md:!min-w-[172px] !min-w-[150px]"
            />
          ))}
        </div>

        <div className="flex lg:justify-end justify-center">
          <BuyBoxLink>
            <div className="flex items-center gap-2 bg-[#5297FF] rounded-[6px] py-2 px-10">
              <div className="md:text-[24px] text-[20px] font-bold uppercase">Buy Now</div>
              <ArrowForwardIcon fill="#fff" />
            </div>
          </BuyBoxLink>
        </div>
      </div>

      <Wrapper>
        {boxList ? (
          boxList?.map((box) => <SaleBox box={box} fetchBox={fetchBox} key={box?.phase} />)
        ) : (
          <div className="w-full max-w-[1232px] mx-auto mb-[42px] px-4">
            <Skeleton minHeight={600} />
          </div>
        )}
      </Wrapper>

      <ModalLoadingV2 />
    </>
  )
}

export default Home
